import { useQuery } from 'react-query';
import { contactsSearch } from 'DataLayer/Contacts/post';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import uniqBy from 'lodash/uniqBy';

import { getUser } from 'store/User/selectors';

export const useContacts = (searchQuery, workspaceId) => {
  /**
   * retain results from previous searches
   */
  const [searchedContacts, setSearchedContacts] = useState([]);

  const user = useSelector(getUser);

  const q = useQuery(['contacts', searchQuery], async () => {
    const data = await contactsSearch(searchQuery, workspaceId);
    /**
     * Empty search will trigger a new cache on the backend.
     * We don't care about the results of an empty search so we ignore
     * the response data.
     */
    if (!searchQuery.length) {
      return searchedContacts;
    }
    const enhancedData = data
      // exlude current user from list
      .filter(contact => user.Guid !== contact.userId)
      .map(contact => {
        return {
          ...contact,
          Id: contact.userId,
          UserId: contact.userId,
          collaboratorUserId: contact.userId,
          FirstName: contact.firstName,
          LastName: contact.lastName,
          Name: `${contact.firstName} ${contact.lastName}`,
          Email: contact.email,
          AvatarUrl: contact.avatarUrl,
          Color: contact.color,
          Presence: contact.presence,
          fromApi: true,
        };
      });
    const finalData = uniqBy(searchedContacts.concat(enhancedData), 'Id');
    setSearchedContacts(finalData);
    return finalData;
  });

  return { searchedContacts, ...q };
};
