import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Form, Input } from 'semantic-ui-react';
import { useDebounce } from 'use-debounce';
import { uniqBy } from 'lodash';

import { getOrderedChatsByWorkspaceId } from 'store/Spaces/selectors';

import { useContacts } from 'Hooks/data/contacts/useContacts';

import Contact from 'Components/Meeting/ManageParticipants/Contact';
import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';

import WorkspaceMember from './WorkspaceMember';

import styles from './WorkspaceMembersList.module.scss';

const WorkspaceMembersList = ({ meeting }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const chats = useSelector(state =>
    getOrderedChatsByWorkspaceId(state, meeting.workspaceId)
  );
  const { searchedContacts, isFetching } = useContacts(
    debouncedSearchQuery,
    meeting.workspaceId
  );

  const chatsFinal = chats.concat(searchedContacts ? searchedContacts : []);

  const chatsFilteredUnique = uniqBy(chatsFinal, 'collaboratorUserId');

  return (
    <>
      <Form autoComplete="off" className={styles.teamspaceSelection}>
        <Form.Field>
          <label>
            <strong>
              <FormattedMessage id="WorkspaceMembersList.Search" />
            </strong>
          </label>
          <Input
            onChange={(event, data) => {
              setSearchQuery(data.value);
            }}
            loading={isFetching}
            value={searchQuery}
            placeholder
            autoFocus
          />
        </Form.Field>
      </Form>
      {chatsFilteredUnique.map(chat => {
        if (chat.fromApi) {
          return (
            <ErrorBoundary key={chat.Id}>
              <Contact
                activeMeeting={meeting}
                chatId={chat.Id}
                chat={chat}
                key={chat.Id}
              />
            </ErrorBoundary>
          );
        }
        return (
          <ErrorBoundary key={chat.Id}>
            <WorkspaceMember
              activeMeeting={meeting}
              chatId={chat.Id}
              chat={chat}
              key={chat.Id}
            />
          </ErrorBoundary>
        );
      })}
    </>
  );
};

export default WorkspaceMembersList;
