// import { rest } from 'msw';
// import { getOtixoBackendApiUrl } from 'config/config';

export const handlers = [
  // rest.post(`${getOtixoBackendApiUrl()}${CONTACTS}`, (req, res, ctx) => {
  //   const { query } = req.body;
  //   return res(
  //     ctx.delay(300),
  //     ctx.status(200),
  //     ctx.json(
  //       chats.filter(
  //         c =>
  //           c.firstName.toLowerCase().includes(query.toLowerCase()) ||
  //           c.lastName.toLowerCase().includes(query.toLowerCase())
  //       )
  //     )
  //   );
  // }),
];
