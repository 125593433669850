import PropTypes from 'prop-types';

import { createMeetingUser } from 'DataLayer/Meeting/post';

import Participant from 'Components/Meeting/ManageParticipants/Participant';
import Avatar from 'Components/Collaborators/Avatar';

const Contact = ({ activeMeeting, chat }) => {
  const user = createMeetingUser({
    userId: chat.UserId,
    firstName: chat.FirstName,
    lastName: chat.LastName,
    email: chat.Email,
    avatarUrl: chat.AvatarUrl,
    color: chat.Color,
    invite: true,
  });

  return (
    <Participant
      activeMeeting={activeMeeting}
      user={user}
      key={user.userId}
      icon={
        <Avatar
          avatarUrl={chat.AvatarUrl}
          color={chat.Color}
          email={chat.Email}
          firstName={chat.FirstName}
          lastName={chat.LastName}
          presence={chat.Presence}
          showStatus
          width={24}
          height={24}
        />
      }
    />
  );
};

Contact.propTypes = {
  activeMeeting: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
};

export default Contact;
