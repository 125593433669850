import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { makeGetChatCollaborator } from 'store/Spaces/Collaborators/selectors';

import { createMeetingUser } from 'DataLayer/Meeting/post';

import SpaceIcon from 'Components/Spaces/SpaceIcon';
import Participant from 'Components/Meeting/ManageParticipants/Participant';
import {
  getChannelIdByRoomId,
  getIsChannelMeetingByRoomId,
} from 'store/Meeting/selectors';

const WorkspaceMember = ({ activeMeeting, chat }) => {
  const getChatCollaborator = useMemo(makeGetChatCollaborator, []);
  const collaborator = useSelector(state =>
    getChatCollaborator(state, chat.Id)
  );
  const isChannelMeeting = useSelector(state =>
    getIsChannelMeetingByRoomId(state, activeMeeting.roomId)
  );
  const channelId = useSelector(state =>
    getChannelIdByRoomId(state, activeMeeting.roomId)
  );
  const user = createMeetingUser({
    userId: collaborator.UserId,
    firstName: collaborator.FirstName,
    lastName: collaborator.LastName,
    email: collaborator.Email,
    avatarUrl: collaborator.AvatarUrl,
    color: collaborator.Color,
    channelId: isChannelMeeting ? channelId : chat.Id,
    invite: true,
  });

  return (
    <Participant
      activeMeeting={activeMeeting}
      user={user}
      key={user.userId}
      icon={<SpaceIcon space={chat} width={24} height={24} showStatus />}
    />
  );
};

WorkspaceMember.propTypes = {
  activeMeeting: PropTypes.object.isRequired,
  chat: PropTypes.object.isRequired,
};

export default WorkspaceMember;
