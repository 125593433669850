import PropTypes from 'prop-types';
import { Modal, Header } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

import WorkspaceMembersList from 'Components/Meeting/ManageParticipants/WorkspaceMembersList';
import ErrorBoundary from 'Components/ErrorBoundaries/ErrorBoundary';

const ManageParticipantsModal = ({ meeting, onClose }) => {
  return (
    <Modal size="tiny" open onClose={onClose}>
      <Header>
        <FormattedMessage id="ManageParticipants.Title" />
        <div className="close_modal">
          <button className="close_modal_button" onClick={onClose} />
        </div>
      </Header>
      <Modal.Content>
        <ErrorBoundary>
          <WorkspaceMembersList meeting={meeting} />
        </ErrorBoundary>
      </Modal.Content>
    </Modal>
  );
};

ManageParticipantsModal.propTypes = {
  meeting: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ManageParticipantsModal;
