import axios from 'axios';
import { getOtixoBackendApiUrl } from 'config/config';
import { CONTACTS } from 'DataLayer/endpoints';

export async function contactsSearch(query, workspaceId) {
  const config = {
    baseURL: getOtixoBackendApiUrl(),
  };

  const { data } = await axios.post(
    CONTACTS,
    {
      query,
      workspaceId,
    },
    config
  );

  if (!data) {
    return [];
  }

  return data;
}
