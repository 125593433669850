/*
 Export all modal components available in our application
*/
//import PropTypes from 'prop-types';
import React from 'react';

import * as modalTypes from './modalTypes';

import DesktopAppReviewModal from 'Components/Modals/DesktopAppReviewModal/DesktopAppReviewModal';
import CongratulationsModal from 'Components/WorkspaceOffer/ThreeMonthTeamspaceUpgradePromotionModal/CongratulationsModal';

const ReconnectionAlertModal = React.lazy(() =>
  import(/* webpackChunkName: "Reconnect" */ './Alerts/Reconnect')
);

const RestrictedForConversationsModal = React.lazy(() =>
  import(
    /* webpackChunkName: "RestrictedForConversationsModal" */ './Conversations/RestrictedForConversationsModal'
  )
);

const DecryptAndDownloadContainer = React.lazy(() =>
  import(
    /* webpackChunkName: "DecryptAndDownloadContainer" */ './Download/DecryptAndDownloadContainer'
  )
);
const PasswordForDecryptContainer = React.lazy(() =>
  import(
    /* webpackChunkName: "PasswordForDecryptContainer" */ './Download/PasswordForDecryptContainer'
  )
);

const DecryptAndDownloadPublicContainer = React.lazy(() =>
  import(
    /* webpackChunkName: "DecryptAndDownloadPublicContainer" */ './Download/DecryptAndDownloadPublicContainer'
  )
);
const PasswordForDecryptPublicContainer = React.lazy(() =>
  import(
    /* webpackChunkName: "PasswordForDecryptPublicContainer" */ './Download/PasswordForDecryptPublicContainer'
  )
);

const AdapterListModal = React.lazy(() =>
  import(/* webpackChunkName: "List" */ './Adapters/List')
);
const AdapterModal = React.lazy(() =>
  import(/* webpackChunkName: "Adapter" */ './Adapters/Adapter')
);

const AddSpaceModal = React.lazy(() =>
  import(/* webpackChunkName: "AddSpace" */ './Space/AddSpace')
);
//const AddSingleSpace = LoadableModal({ loader: () => import(/* webpackChunkName: "AddSingleSpace" */'./SingleSpace/AddSingleSpace') });
const AddTeamSpaceModal = React.lazy(() =>
  import(
    /* webpackChunkName: "AddTeamSpaceModal" */ './Space/AddTeamSpaceModal'
  )
);

const DeleteSpaceModal = React.lazy(() =>
  import(/* webpackChunkName: "DeleteSpaceModal" */ './Space/DeleteSpaceModal')
);
const ArchiveSpaceModal = React.lazy(() =>
  import(
    /* webpackChunkName: "ArchiveSpaceModal" */ './Space/ArchiveSpaceModal'
  )
);
const UnarchiveSpaceModal = React.lazy(() =>
  import(
    /* webpackChunkName: "UnarchiveSpaceModal" */ './Space/UnarchiveSpaceModal'
  )
);
const LeaveSpaceModal = React.lazy(() =>
  import(/* webpackChunkName: "LeaveSpaceModal" */ './Space/LeaveSpaceModal')
);
const LeavePrivateChatModal = React.lazy(() =>
  import(
    /* webpackChunkName: "LeavePrivateChatModal" */ './PrivateChat/LeavePrivateChatModal'
  )
);
const EditSpaceModal = React.lazy(() =>
  import(/* webpackChunkName: "EditSpaceModal" */ './Space/EditSpaceModal')
);
const ExportChannelModal = React.lazy(() =>
  import(
    /* webpackChunkName: "ExportChannelModal" */ './Space/ExportChannelModal'
  )
);

const SpaceChangeAvatarModal = React.lazy(() =>
  import(
    /* webpackChunkName: "SpaceChangeAvatarModal" */ './Space/SpaceChangeAvatarModal'
  )
);

const AddWorkspaceMemberModal = React.lazy(() =>
  import(
    /* webpackChunkName: "AddWorkspaceMemberModal" */ './WorkspaceMember/AddWorkspaceMemberModal'
  )
);

const AddMembersToFirstWorkspace = React.lazy(() =>
  import(
    /* webpackChunkName: "AddMembersToFirstWorkspace" */ './AddMembersToFirstWorkspace/AddMembersToFirstWorkspace'
  )
);

const AddFirstChannelModal = React.lazy(() =>
  import(
    /* webpackChunkName: "AddFirstChannelModal" */ './AddFirstChannelModal/AddFirstChannelModal'
  )
);

const PurchaseWorkspacePlanModal = React.lazy(() =>
  import(
    /* webpackChunkName: "PurchaseWorkspacePlanModal" */ './Workspace/PurchaseWorkspacePlan/PurchaseWorkspacePlanModal'
  )
);

const PurchaseWorkspacePlanCompleteModal = React.lazy(() =>
  import(
    /* webpackChunkName: "PurchaseWorkspacePlanCompleteModal" */ './Workspace/PurchaseWorkspacePlan/PurchaseWorkspacePlanCompleteModal'
  )
);

const MeetingsRestrictedModal = React.lazy(() =>
  import(
    /* webpackChunkName: "MeetingsRestrictedModal" */ './Meeting/MeetingsRestrictedModal'
  )
);

const WorkspaceFolderUploadUnavailable = React.lazy(() =>
  import(
    /* webpackChunkName: "WorkspaceFolderUploadUnavailable" */ './WorkspaceFolderUploadUnavailable/WorkspaceFolderUploadUnavailable'
  )
);

const CreateMeetingModalNew = React.lazy(() =>
  import(
    /* webpackChunkName: "CreateMeetingModalNew" */ './Meeting/CreateMeetingModal/CreateMeetingModalNew'
  )
);

const DeleteCollaboratorModal = React.lazy(() =>
  import(
    /* webpackChunkName: "DeleteCollaboratorModal" */ './Collaborator/DeleteCollaboratorModal'
  )
);
const ChangeToMemberModal = React.lazy(() =>
  import(
    /* webpackChunkName: "ChangeToMemberModal" */ './WorkspaceMember/ChangeToMemberModal'
  )
);
const ChangeToSingleGuestModal = React.lazy(() =>
  import(
    /* webpackChunkName: "ChangeToSingleGuestModal" */ './WorkspaceMember/ChangeToSingleGuestModal'
  )
);
//const AddMemberToSpaceModal = LoadableModal({ loader: () => import(/* webpackChunkName: "AddMemberToSpaceModal" */'./Collaborator/AddMemberToSpaceModal') });

const DeleteWorkspaceMemberModal = React.lazy(() =>
  import(
    /* webpackChunkName: "DeleteWorkspaceMemberModal" */ './WorkspaceMember/DeleteWorkspaceMemberModal'
  )
);

const DeleteLinkedItemModal = React.lazy(() =>
  import(
    /* webpackChunkName: "DeleteLinkedItemModal" */ './LinkedItem/DeleteLinkedItemModal'
  )
);

const TransferLinkedItemModal = React.lazy(() =>
  import(
    /* webpackChunkName: "TransferLinkedItemModal" */ './LinkedItem/TransferLinkedItemModal'
  )
);

const TransferLinkedItemSelectTargetModal = React.lazy(() =>
  import(
    /* webpackChunkName: "TransferLinkedItemSelectTargetModal" */ './LinkedItem/TransferLinkedItemSelectTargetModal'
  )
);
const RenameLinkedItemModal = React.lazy(() =>
  import(
    /* webpackChunkName: "RenameLinkedItemModal" */ './LinkedItem/RenameLinkedItemModal'
  )
);

const ResourceAddFolder = React.lazy(() =>
  import(
    /* webpackChunkName: "ResourceAddFolderModal" */ './Resource/ResourceAddFolderModal'
  )
);
const RenameResourceModal = React.lazy(() =>
  import(
    /* webpackChunkName: "RenameResourceModal" */ './Resource/RenameResourceModal'
  )
);
const DeleteResourceModal = React.lazy(() =>
  import(
    /* webpackChunkName: "DeleteResourceModal" */ './Resource/DeleteResourceModal'
  )
);
const ResourceLinkModal = React.lazy(() =>
  import(
    /* webpackChunkName: "ResourceLinkModal" */ './Resource/ResourceLinkModal'
  )
);
const RemoveResourceLinkModal = React.lazy(() =>
  import(
    /* webpackChunkName: "RemoveResourceLinkModal" */ './Resource/RemoveResourceLinkModal'
  )
);
const ResourceLinkSettingsModal = React.lazy(() =>
  import(
    /* webpackChunkName: "ResourceLinkSettingsModal" */ './Resource/ResourceLinkSettingsModal'
  )
);
// const PreviewerModalContainer = React.lazy(() =>
//   import(/* webpackChunkName: "PreviewerModalContainer" */ './Previewer/PreviewerModalContainer')
// );
const LinkedItemPreviewer = React.lazy(() =>
  import(
    /* webpackChunkName: "LinkedItemPreviewer" */ './Previewer/LinkedItemPreviewer/LinkedItemPreviewer'
  )
);
const ResourcePreviewer = React.lazy(() =>
  import(
    /* webpackChunkName: "ResourcePreviewer" */ './Previewer/ResourcePreviewer/ResourcePreviewer'
  )
);
const SingleResourcePreviewer = React.lazy(() =>
  import(
    /* webpackChunkName: "SingleResourcePreviewer" */ './Previewer/SingleResourcePreviewer/SingleResourcePreviewer'
  )
);
const ChatPreviewer = React.lazy(() =>
  import(
    /* webpackChunkName: "ChatPreviewer" */ './Previewer/ChatPreviewer/ChatPreviewer'
  )
);

const TransferProgressModal = React.lazy(() =>
  import(
    /* webpackChunkName: "TransferProgressModal" */ './Transfer/TransferProgressModal'
  )
);

const UploadProgress = React.lazy(() =>
  import(/* webpackChunkName: "UploadProgress" */ './UploadProgress')
);

const ShareLinkedItemsProgress = React.lazy(() =>
  import(
    /* webpackChunkName: "ShareLinkedItemsProgress" */ 'Components/Modals/LinkedItem/Share/ShareLinkedItemsProgressModal'
  )
);

const UploadFiles = React.lazy(() =>
  import(/* webpackChunkName: "UploadFiles" */ './UploadFiles')
);

const DeleteRepositoryModal = React.lazy(() =>
  import(
    /* webpackChunkName: "DeleteRepositoryModal" */ './Repository/DeleteRepositoryModal'
  )
);
const RenameRepositoryModal = React.lazy(() =>
  import(
    /* webpackChunkName: "RenameRepositoryModal" */ './Repository/RenameRepositoryModal'
  )
);
const EditRepositoryModal = React.lazy(() =>
  import(
    /* webpackChunkName: "EditRepositoryModal" */ './Repository/EditRepositoryModal'
  )
);
const ReconnectRepositoryModal = React.lazy(() =>
  import(
    /* webpackChunkName: "ReconnectRepositoryModal" */ './Repository/ReconnectRepositoryModal'
  )
);

const CopyModal = React.lazy(() =>
  import(/* webpackChunkName: "CopyModal" */ './Copy/CopyModal')
);

const TransferToOtixoDriveModal = React.lazy(() =>
  import(
    /* webpackChunkName: "TransferToOtixoDriveModal" */ './TransferToOtixoDrive/TransferToOtixoDriveModal'
  )
);

const AddToSpaceModal = React.lazy(() =>
  import(
    /* webpackChunkName: "AddToSpaceModal" */ './Space/AddToSpace/AddToSpaceModal'
  )
);

const UpgradeModal = React.lazy(() =>
  import(/* webpackChunkName: "UpgradeModal" */ './Upgrade/UpgradeModal')
);

const WorkspaceUpgradeModal = React.lazy(() =>
  import(
    /* webpackChunkName: "WorkspaceUpgradeModal" */ './Upgrade/WorkspaceUpgradeModal'
  )
);

const PlanChangeNotAllowed = React.lazy(() =>
  import(
    /* webpackChunkName: "PlanChangeNotAllowed" */ './Upgrade/PlanChangeNotAllowed'
  )
);
const BandwidthExceededModal = React.lazy(() =>
  import(
    /* webpackChunkName: "BandwidthExceededModal" */ './Upgrade/BandwidthExceededModal'
  )
);

const GetTransferSubscriptionModal = React.lazy(() =>
  import(
    /* webpackChunkName: "GetTransferSubscriptionModal" */ './Upgrade/GetTransferSubscriptionModal'
  )
);

const NoQuotaModal = React.lazy(() =>
  import(/* webpackChunkName: "NoQuotaModal" */ './Upgrade/NoQuotaModal')
);
const NoSpaceQuotaModal = React.lazy(() =>
  import(
    /* webpackChunkName: "NoSpaceQuotaModal" */ './Upgrade/NoSpaceQuotaModal'
  )
);
const PublicLinkSizeRestrictedModal = React.lazy(() =>
  import(
    /* webpackChunkName: "PublicLinkSizeRestrictedModal" */ './Upgrade/PublicLinkSizeRestrictedModal'
  )
);
const AppsExceededModal = React.lazy(() =>
  import(
    /* webpackChunkName: "AppsExceededModal" */ './Upgrade/AppsExceededModal'
  )
);
const AdapterRetiredModal = React.lazy(() =>
  import(
    /* webpackChunkName: "AdapterRetiredModal" */ './Adapters/AdapterRetiredModal'
  )
);
// const TransferChargeWarningModal =  LoadableComponent({ loader: () => import('./Upgrade/TransferChargeWarningModal')})
const BandwidthInformationModal = React.lazy(() =>
  import(
    /* webpackChunkName: "BandwidthInformationModal" */ './Bandwidth/BandwidthInformationModal'
  )
);
const ShareFolderInformationModal = React.lazy(() =>
  import(
    /* webpackChunkName: "ShareFolderInformationModal" */ './Bandwidth/ShareFolderInformationModal'
  )
);

const ShareFilesAndMessagesWithChannel = React.lazy(() =>
  import(
    /* webpackChunkName: "ShareFilesAndMessagesWithChannel" */ './ShareFilesAndMessagesWithChannel/ShareFilesAndMessagesWithChannel'
  )
);

const MigrateChat = React.lazy(() =>
  import(/* webpackChunkName: "MigrateChat" */ './Migration/MigrateChatModal')
);

const CloudCopyRestrictedModal = React.lazy(() =>
  import(
    /* webpackChunkName: "CloudCopyRestrictedModal" */ './Upgrade/CloudCopyRestrictedModal'
  )
);

const AddSpaceFolderModal = React.lazy(() =>
  import(
    /* webpackChunkName: "AddSpaceFolderModal" */ './Space/AddSpaceFolderModal'
  )
);

const SpaceChatDeleteMessageModal = React.lazy(() =>
  import(
    /* webpackChunkName: "SpaceChatDeleteMessageModal" */ './SpaceChat/SpaceChatDeleteMessageModal'
  )
);

const DeleteComment = React.lazy(() =>
  import(/* webpackChunkName: "DeleteComment" */ './Comments/DeleteComment')
);

const ChangeToSingleGuestErrorModal = React.lazy(() =>
  import(
    /* webpackChunkName: "ChangeToSingleGuestErrorModal" */ './WorkspaceMember/ChangeToSingleGuestErrorModal'
  )
);

const AddWorkspaceModal = React.lazy(() =>
  import(
    /* webpackChunkName: "AddWorkspaceModal" */ './Workspace/AddWorkspaceModal'
  )
);
const LeaveWorkspaceModal = React.lazy(() =>
  import(
    /* webpackChunkName: "LeaveWorkspaceModal" */ './Workspace/LeaveWorkspaceModal'
  )
);
const WorkspaceExportStartedModal = React.lazy(() =>
  import(
    /* webpackChunkName: "WorkspaceExportStartedModal" */ './Workspace/Export/WorkspaceExportStartedModal'
  )
);

const SaveReminderModal = React.lazy(() =>
  import(
    /* webpackChunkName: "SaveReminderModal" */ './Reminders/SaveReminderModal'
  )
);

const AddReminderModal = React.lazy(() =>
  import(
    /* webpackChunkName: "AddReminderModal" */ './Reminders/AddReminderModal'
  )
);

const SnoozeReminderModal = React.lazy(() =>
  import(
    /* webpackChunkName: "SnoozeReminderModal" */ './Reminders/SnoozeReminderModal'
  )
);

const EducationApplicationModal = React.lazy(() =>
  import(
    /* webpackChunkName: "EducationApplicationModal" */ './Workspace/Upgrade/EducationApplicationModal'
  )
);

const RemoveNoteModal = React.lazy(() =>
  import(
    /* webpackChunkName: "RemoveNoteModal" */ 'Components/Notes/RemoveNoteModal'
  )
);

const RestoreNoteModal = React.lazy(() =>
  import(
    /* webpackChunkName: "RestoreNoteModal" */ 'Components/Notes/RestoreNoteModal'
  )
);

const NoteHistoryModal = React.lazy(() =>
  import(
    /* webpackChunkName: "NoteHistoryModal" */ 'Components/Notes/NoteHistoryModal'
  )
);

const SuggestionsModal = React.lazy(() =>
  import(
    /* webpackChunkName: "SuggestionsModal" */ 'Components/Modals/Suggestions/SuggestionsModal'
  )
);

const Spring2024PromotionModalAccepted = React.lazy(() =>
  import(
    /* webpackChunkName: "Spring2024PromotionModalAccepted" */ 'Components/WorkspaceOffer/Spring2024Trial/Spring2024PromotionModalAccepted'
  )
);

const Spring2024PromotionMilestonesModal = React.lazy(() =>
  import(
    /* webpackChunkName: "Spring2024PromotionMilestonesModal" */ 'Components/WorkspaceOffer/Spring2024Trial/Spring2024PromotionMilestonesModal'
  )
);

const DownloadDesktopOfferModal = React.lazy(() =>
  import(
    /* webpackChunkName: "DownloadDesktopOfferModal" */ 'Components/WorkspaceOffer/DownloadDesktopOffer/DownloadDesktopOfferModal'
  )
);

const AppFeedbackOfferModal = React.lazy(() =>
  import(
    /* webpackChunkName: "AppFeedbackOffer" */ 'Components/WorkspaceOffer/AppFeedbackOffer/AppFeedbackOfferModal'
  )
);

const AppFeedbackOfferAcceptedModal = React.lazy(() =>
  import(
    /* webpackChunkName: "AppFeedbackOffer" */ 'Components/WorkspaceOffer/AppFeedbackOffer/AppFeedbackOfferAcceptedModal'
  )
);

const CreateTagModal = React.lazy(() =>
  import(
    /* webpackChunkName: "CreateTagModal" */ 'Components/Tagger/CreateTagModal'
  )
);

const ManageTagsModal = React.lazy(() =>
  import(
    /* webpackChunkName: "ManageTagsModal" */ 'Components/Tagger/ManageTagsModal'
  )
);

const DeleteTagModal = React.lazy(() =>
  import(
    /* webpackChunkName: "DeleteTagModal" */ 'Components/Tagger/DeleteTagModal'
  )
);

const allModals = {
  [modalTypes.WORKSPACE_ADD]: AddWorkspaceModal,
  [modalTypes.CREATE_TAG]: CreateTagModal,
  [modalTypes.MANAGE_TAGS]: ManageTagsModal,
  [modalTypes.DELETE_TAG]: DeleteTagModal,

  [modalTypes.WORKSPACE_LEAVE]: LeaveWorkspaceModal,
  [modalTypes.WORKSPACE_EXPORT_STARTED]: WorkspaceExportStartedModal,

  [modalTypes.TEAM_SPACE_ADD]: AddTeamSpaceModal,
  [modalTypes.SPACE_ADD]: AddSpaceModal,
  [modalTypes.SPACE_DELETE]: DeleteSpaceModal,
  [modalTypes.SPACE_ARCHIVE]: ArchiveSpaceModal,
  [modalTypes.SPACE_UNARCHIVE]: UnarchiveSpaceModal,
  [modalTypes.CHANNEL_EXPORT]: ExportChannelModal,
  [modalTypes.SPACE_RENAME]: EditSpaceModal,
  [modalTypes.SPACE_CHANGE_AVATAR]: SpaceChangeAvatarModal,
  [modalTypes.SPACE_LEAVE]: LeaveSpaceModal,
  [modalTypes.PRIVATECHAT_LEAVE]: LeavePrivateChatModal,

  [modalTypes.WORKSPACE_MEMBER_ADD]: AddWorkspaceMemberModal,

  [modalTypes.COLLABORATOR_REMOVE]: DeleteCollaboratorModal,
  [modalTypes.MEMBER_CHANGE_TO_MEMBER]: ChangeToMemberModal,
  [modalTypes.MEMBER_CHANGE_TO_SINGLE_GUEST]: ChangeToSingleGuestModal,

  [modalTypes.MEMBER_CHANGE_TO_SINGLE_GUEST_ERROR]:
    ChangeToSingleGuestErrorModal,

  [modalTypes.WORKSPACE_MEMBER_REMOVE]: DeleteWorkspaceMemberModal,

  [modalTypes.RESOURCE_ADD_FOLDER]: ResourceAddFolder,
  [modalTypes.RESOURCE_RENAME]: RenameResourceModal,
  [modalTypes.RESOURCE_DELETE]: DeleteResourceModal,
  [modalTypes.RESOURCE_LINK]: ResourceLinkModal,
  [modalTypes.RESOURCE_LINK_REMOVE]: RemoveResourceLinkModal,
  [modalTypes.RESOURCE_LINK_SETTINGS]: ResourceLinkSettingsModal,

  [modalTypes.CHAT_PREVIEW]: ChatPreviewer,
  [modalTypes.SINGLE_RESOURCE_PREVIEW]: SingleResourcePreviewer,
  [modalTypes.RESOURCES_PREVIEW]: ResourcePreviewer,
  [modalTypes.LINKED_ITEM_PREVIEW]: LinkedItemPreviewer,

  [modalTypes.TRANSFERS_PROGRESS]: TransferProgressModal,

  [modalTypes.RESOURCE_UPLOAD_PROGRESS]: UploadProgress,
  [modalTypes.SHARE_LINKED_ITEMS_PROGRESS]: ShareLinkedItemsProgress,
  [modalTypes.RESOURCE_UPLOAD_FILES]: UploadFiles,

  [modalTypes.REPOSITORY_DELETE]: DeleteRepositoryModal,
  [modalTypes.REPOSITORY_RENAME]: RenameRepositoryModal,

  [modalTypes.REPOSITORY_RECONNECT]: ReconnectRepositoryModal,
  [modalTypes.REPOSITORY_EDIT]: EditRepositoryModal,

  [modalTypes.LINKEDITEM_RENAME]: RenameLinkedItemModal,
  [modalTypes.LINKEDITEM_DELETE]: DeleteLinkedItemModal,
  [modalTypes.LINKEDITEM_TRANSFER]: TransferLinkedItemModal,
  [modalTypes.TRANSFER_LINKEDITEM_SELECT_TARGET]:
    TransferLinkedItemSelectTargetModal,

  [modalTypes.COPY]: CopyModal,
  [modalTypes.TRANSFER_TO_OTIXO_DRIVE]: TransferToOtixoDriveModal,

  [modalTypes.UPGRADE]: UpgradeModal,
  [modalTypes.WORKSPACE_UPGRADE]: WorkspaceUpgradeModal,
  [modalTypes.PLAN_CHANGE_NOT_ALLOWED]: PlanChangeNotAllowed,
  [modalTypes.BANDWIDTH_EXCEEDED]: BandwidthExceededModal,

  [modalTypes.GET_TRANSFER_SUBSCRIPTION]: GetTransferSubscriptionModal,

  [modalTypes.NO_QUOTA]: NoQuotaModal,
  [modalTypes.NO_SPACE_QUOTA]: NoSpaceQuotaModal,
  [modalTypes.PUBLIC_LINK_SIZE_RESTRICTED]: PublicLinkSizeRestrictedModal,
  [modalTypes.APPS_EXCEEDED]: AppsExceededModal,
  [modalTypes.ADAPTER_RETIRED]: AdapterRetiredModal,
  [modalTypes.BANDWIDTH_INFORMATION]: BandwidthInformationModal,
  [modalTypes.SHARE_FOLDER_INFORMATION]: ShareFolderInformationModal,

  [modalTypes.CLOUD_COPY_RESTRICTED]: CloudCopyRestrictedModal,
  [modalTypes.ALERT_RECONNECT]: ReconnectionAlertModal,

  [modalTypes.ADAPTERS]: AdapterListModal,
  [modalTypes.ADAPTER]: AdapterModal,

  [modalTypes.DECRYPT_AND_DOWNLOAD]: DecryptAndDownloadContainer,
  [modalTypes.PASSWORD_FOR_DECRYPT]: PasswordForDecryptContainer,

  [modalTypes.DECRYPT_AND_DOWNLOAD_PUBLIC]: DecryptAndDownloadPublicContainer,
  [modalTypes.PASSWORD_FOR_DECRYPT_PUBLIC]: PasswordForDecryptPublicContainer,

  [modalTypes.ADD_ITEM_TO_SPACE]: AddToSpaceModal,

  [modalTypes.ADD_SPACE_FOLDER]: AddSpaceFolderModal,

  [modalTypes.SPACE_CHAT_DELETE_MESSAGE]: SpaceChatDeleteMessageModal,

  [modalTypes.FILE_COMMENTS_DELETE]: DeleteComment,

  [modalTypes.SHARE_FILES_AND_MESSAGES_WITH_CHANNEL]:
    ShareFilesAndMessagesWithChannel,

  [modalTypes.CHAT_MIGRATION]: MigrateChat,

  [modalTypes.CONVERSATION_FEATURE_RESTRICTED]: RestrictedForConversationsModal,

  [modalTypes.SAVE_REMINDER]: SaveReminderModal,

  [modalTypes.ADD_REMINDER]: AddReminderModal,

  [modalTypes.SNOOZE_REMINDER]: SnoozeReminderModal,

  [modalTypes.ADD_MEMBERS_TO_FIRST_WORKSPACE]: AddMembersToFirstWorkspace,
  [modalTypes.ADD_FIRST_CHANNEL_MODEL]: AddFirstChannelModal,
  [modalTypes.PURCHASE_WORKSPACE_PLAN]: PurchaseWorkspacePlanModal,

  [modalTypes.PURCHASE_WORKSPACE_PLAN_COMPLETE]:
    PurchaseWorkspacePlanCompleteModal,

  [modalTypes.MEETINGS_RESTRICTED]: MeetingsRestrictedModal,
  [modalTypes.WORKSPACE_FOLDER_UPLOAD_UNAVAILABLE]:
    WorkspaceFolderUploadUnavailable,

  [modalTypes.SELECT_MEETING_USERS]: CreateMeetingModalNew,

  [modalTypes.EDUCATION_APPLICATION]: EducationApplicationModal,
  [modalTypes.DESKTOP_APP_REVIEW]: DesktopAppReviewModal,
  [modalTypes.CONGRATULATIONS_MODAL]: CongratulationsModal,
  [modalTypes.REMOVE_NOTE_MODAL]: RemoveNoteModal,
  [modalTypes.RESTORE_NOTE_MODAL]: RestoreNoteModal,
  [modalTypes.NOTE_HISTORY_MODAL]: NoteHistoryModal,

  [modalTypes.SUGGESTIONS_MODAL]: SuggestionsModal,

  [modalTypes.SPRING_2024_PROMOTION_ACCEPTED]: Spring2024PromotionModalAccepted,
  [modalTypes.SPRING_2024_PROMOTION_MILESTONES]:
    Spring2024PromotionMilestonesModal,
  [modalTypes.DOWNLOAD_DESKTOP_OFFER]: DownloadDesktopOfferModal,
  [modalTypes.APP_FEEDBACK_OFFER]: AppFeedbackOfferModal,
  [modalTypes.APP_FEEDBACK_OFFER_ACCEPTED]: AppFeedbackOfferAcceptedModal,
};

export default allModals;
