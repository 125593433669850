/* eslint-disable react/jsx-no-literals */
import { CodeBlock } from '@atlaskit/code';
import { getNonUserWebSocketUrl } from 'config/config';
import { getToken } from 'Helpers/BrowserApi/localStorage';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Container, Form, Segment } from 'semantic-ui-react';
import { connectWebSocket } from 'store/websocket-middleware';

const Websocket = () => {
  const [token, setToken] = useState(getToken());
  const [connectionId, setConnectionId] = useState('');
  const dispatch = useDispatch();
  const connect = () => {
    dispatch(connectWebSocket(getNonUserWebSocketUrl(token)));
  };

  return (
    <Container>
      <Segment>
        <Form>
          <ol>
            <li>Open Developer Tools > Network</li>
            <li>
              Enter a token to be sent with the WebSocket connection
              <Form.Field>
                <input
                  onChange={e => setToken(e.target.value)}
                  placeholder="token"
                  value={token}
                  id="token"
                />
              </Form.Field>
            </li>
            <li>
              Click the button below to open the WebSocket
              <br />
              <Button
                size="tiny"
                compact
                basic
                primary
                onClick={connect}
                type="button"
              >
                Open WebSocket
              </Button>
            </li>
            <li>
              The WebSocket connection should appear in Developer Tools >
              Network
            </li>
            <li>Select the WebSocket from the list of requests</li>
            <li>Select the Messages tab</li>
            <li>There should be ping/pong messages being sent/received</li>
            <li>The pong message should include a connectionId</li>
            <li>
              Copy the connectionId and paste it below
              <Form.Field>
                <input
                  onChange={e => setConnectionId(e.target.value)}
                  placeholder="connectionId"
                  value={connectionId}
                  id="connectionId"
                />
              </Form.Field>
            </li>
            <li>
              Run the following cURL in a Terminal
              <br />
              <CodeBlock
                text={`curl -v -POST -H "Content-type: application/json" -d '[
  {
    "connectionId": "${connectionId}",
    "body": "{\\"message\\":\\"hello\\"}"
  }]' 'https://push.ws.public.dev.we.team/message'`}
              />
            </li>
            <li>The message should be received by the WebSocket</li>
          </ol>
        </Form>
      </Segment>
    </Container>
  );
};

export default Websocket;
