import axios from 'axios';
import { urls } from './urls';
import {
  navigate,
  isInIframe,
  navigateIframeParent,
} from 'Helpers/BrowserApi/window';
import { getToken } from 'Helpers/BrowserApi/localStorage';

import { supportedLocales } from 'translations/getMessageByLocale';

export const NUMBER_OF_GUESTS_PER_WORK_SPACE_MEMBER = 1;

/*
These can be found inside the .env files in config/env
*/
export const ENVIRONMENTS = {
  local: 'local',
  development: 'development',
  test: 'test',
  production: 'production',
};

export function multiLanguageEnabled() {
  return true;
}

export function getOtixoEnvironment() {
  return process.env.REACT_APP_ENVIRONMENT;
}

export function getOfferPostponeDurationMinutes() {
  return process.env.REACT_APP_OFFER_POSTPONE_DURATION_MINUTES;
}

export function getEditCommentExpiryMinutes() {
  return process.env.REACT_APP_EDIT_COMMENT_EXPIRY_MINUTES ?? 15;
}

export function getDaysToShowDesktopAppReviewModal() {
  return process.env.REACT_APP_DAYS_TO_SHOW_DESKTOP_APP_REVIEW;
}

export function isOtixoEnvProduction() {
  return process.env.REACT_APP_ENVIRONMENT === 'production';
}

export function shouldUseAuthToken() {
  return isDesktopApp() || isNodeEnvDevelopment();
}

export function isCypressTestRun() {
  return window.Cypress;
}

export function isNodeEnvDevelopment() {
  return process.env.NODE_ENV === 'development';
}

export const isWebApp = () => !isDesktopApp();

export function isDesktopApp() {
  // https://github.com/electron/electron/issues/2288
  // Renderer process
  if (
    typeof window !== 'undefined' &&
    typeof window.process === 'object' &&
    window.process.type === 'renderer'
  ) {
    return true;
  }

  // Main process
  if (
    typeof process !== 'undefined' &&
    typeof process.versions === 'object' &&
    !!process.versions.electron
  ) {
    return true;
  }

  // Detect the user agent when the `nodeIntegration` option is set to true
  if (
    typeof navigator === 'object' &&
    typeof navigator.userAgent === 'string' &&
    navigator.userAgent.indexOf('Electron') >= 0
  ) {
    return true;
  }

  return false;
}

export function showUpgradeForEmployee() {
  return process.env.REACT_APP_SHOW_UPGRADE_FOR_EMPLOYEE === 'true';
}

export function getFrontendApplicatonUrl() {
  if (isNodeEnvDevelopment()) {
    return `http://localhost:3000${getOtixoBasePath()}`;
  }
  return `${getDomain()}${getOtixoBasePath()}`;
}

export function getOtixoZapierProxyUrl(id) {
  return `${getDomain()}/zapProxy?action=`;
}

export function getOtixoZapierCreateProxyUrl(id) {
  return `${getOtixoZapierProxyUrl()}create&id=${id}`;
}

export function getOtixoZapierEditProxyUrl(id) {
  return `${getOtixoZapierProxyUrl()}edit&id=${id}`;
}

export function getZapierOAuthUrl() {
  if (isDesktopApp()) {
    return urls.otixoZapierOAuthUrl;
  }
  const state = encodeURIComponent('app/integration/space/manage');
  return `${urls.otixoZapierOAuthUrl}${state}`;
}

function getOAuthState(invitationToken) {
  const params = [];
  if (invitationToken) {
    params.push(`token=${invitationToken}`);
  }
  if (isDesktopApp()) {
    params.push('app=mobile');
    params.push('continue=otixo://userlogin');
  }
  return params.join(',');
}

export function navigateToAppleOAuthUrl(invitationToken) {
  const state = getOAuthState(invitationToken);
  navigateToOAuthUrl(`${urls.otixoAppleOAuthUrl}${state}`);
}

export function navigateToGoogleOAuthUrl(invitationToken) {
  const state = getOAuthState(invitationToken);
  navigateToOAuthUrl(`${urls.otixoGoogleOAuthUrl},${state}`);
}

export function navigateToOAuthUrl(url) {
  if (isDesktopApp()) {
    window.open(url);
  } else if (isInIframe()) {
    navigateIframeParent(url);
  } else {
    navigate(url);
  }
}

export function getUserWebSocketUrl() {
  const domain = new URL(getDomain());
  const url = `wss://ws.${domain.host}`;
  return shouldUseAuthToken() ? `${url}?token=${getToken()}` : url;
}

export function getNonUserWebSocketUrl(token) {
  const domain = new URL(getDomain());
  return `wss://ws.public.${domain.host}?token=${token}`;
}

let domain;

setDomain(urls.otixoDomain);

export function setDomain(newDomain) {
  if (newDomain) {
    domain = newDomain;
    axios.defaults.baseURL = getOtixoApiUrl();
  }
}

export function getDomain() {
  return domain;
}

export function getOtixoApiUrl() {
  return `${getDomain()}/v3.0/`;
}

export function getOtixoBackendApiUrl() {
  return `${getDomain()}/otixo-backend/`;
}

/*
  This file is generated during npm run build.
  Do not expect it to be available during development.
*/
export function getAssetManifestChecksumUrl() {
  return `${getFrontendApplicatonUrl()}/asset-manifest-checksum.txt`;
}

export function getMaintenanceModeUrl() {
  return `${getFrontendApplicatonUrl()}/maintenance`;
}

export function getNewVersionPollInteral() {
  try {
    return parseInt(process.env.REACT_APP_NEW_APP_VERSION_POLL_INTERVAL);
  } catch (e) {
    return 1000 * 60; // 1 minute
  }
}

export function getOtixoPDFViewerUrl(url) {
  return `${getDomain()}/pdf-viewer/web/viewer.html?file=${encodeURIComponent(
    url
  )}`;
}

export function getCallbackUrl(token) {
  return `${getFrontendApplicatonUrl()}callback/${token}`;
}

export function getOtixoDownloadUrl(id, spaceId) {
  const url = `${getOtixoApiUrl()}resources/${id}/content`;
  if (spaceId) {
    return `${url}?spaceId=${spaceId}`;
  }
  return url;
}

export function getOtixoDecryptDownloadUrl(id, spaceId) {
  const url = `${getOtixoApiUrl()}resources/${id}/content.decrypt`;
  if (spaceId) {
    return `${url}?spaceId=${spaceId}`;
  }
  return url;
}

export function getOtixoDecryptWithPasswordDownloadUrl(
  resourceId,
  spaceId,
  password
) {
  const url = `${getOtixoApiUrl()}resources/${resourceId}/content.decrypt?password=${password}`;
  if (spaceId) {
    return `${url}?&spaceId=${spaceId}`;
  }
  return url;
}

export function getOtixoArchiveDownloadUrl(ids, spaceId) {
  const url = `${getOtixoApiUrl()}resources/content.archive?id=${ids}`;
  if (spaceId) {
    return `${url}&spaceId=${spaceId}`;
  }
  return url;
}

export function getOtixoPublicDownloadUrl(
  id,
  publicLinkId,
  publicLinkPassword
) {
  return `${getOtixoApiUrl()}linkedResources/${id}/content?linkId=${publicLinkId}&linkPassword=${publicLinkPassword}`;
}

export function getOtixoPublicDecryptDownloadUrl(
  id,
  publicLinkId,
  publicLinkPassword
) {
  return `${getOtixoApiUrl()}linkedResources/${id}/content.decrypt?linkId=${publicLinkId}&linkPassword=${publicLinkPassword}`;
}

export function getOtixoPublicDecryptWithPasswordDownloadUrl(
  resourceId,
  publicLinkId,
  publicLinkPassword,
  resourcePassword
) {
  return `${getOtixoApiUrl()}linkedResources/${resourceId}/content.decrypt?linkId=${publicLinkId}&linkPassword=${publicLinkPassword}&filePassword=${resourcePassword}`;
}

export function getOtixoPublicArchiveDownloadUrl(
  ids,
  publicLinkId,
  publicLinkPassword
) {
  return `${getOtixoApiUrl()}linkedResources/content.archive?id=${ids}&linkId=${publicLinkId}&linkPassword=${publicLinkPassword}`;
}

export function getOtixoBasePath() {
  return urls.otixoBasePath;
}

export function getOtixoLoginUrl() {
  return urls.otixoLoginUrl;
}

export function getOAuthRedirectDesktop() {
  return 'otixo://adapter-added';
}

export function getOAuthRedirectUrl(spaceId = '') {
  return `${getFrontendApplicatonUrl()}/cloud-drive-auth-complete/${spaceId}`;
}

export function getRecurlyAccountUrl(pageToken) {
  return `${urls.otixoRecurlyAccountUrl}/${pageToken}`;
}

export function getRecurlyPublicKey() {
  return `${urls.otixoRecurlyPublicKey}`;
}

export function getPricingPageUrl(locale) {
  if (locale === supportedLocales.de) {
    return `${urls.marketingUrl}/preise`;
  }
  return `${urls.marketingUrl}/en/pricing`;
}

export function getPrivacyPolicyUrl(locale) {
  if (locale === supportedLocales.de) {
    return `${urls.marketingUrl}/datenschutzrichtlinie-basic`;
  }
  return `${urls.marketingUrl}/en/privacy-policy-basic`;
}

export function getTermsUrl(locale) {
  if (locale === supportedLocales.de) {
    return `${urls.marketingUrl}/nutzungsbedingungen-basic`;
  }
  return `${urls.marketingUrl}/en/terms-of-service-basic`;
}

export function getContactUrl(locale) {
  if (locale === supportedLocales.de) {
    return `${urls.marketingUrl}/unternehmen/kontakt`;
  }
  return `${urls.marketingUrl}/en/contact`;
}

export function getSupportEmail() {
  return urls.supportEmail;
}

export function getSupportEmailMailToLink() {
  return `mailto:${getSupportEmail()}`;
}

export function getDesktopDownloadUrl() {
  return urls.desktopDownloadUrl;
}

export function getViewerJsUrl() {
  return `${getDomain()}/ViewerJS/#`;
}

export function getMarketingDownloadsUrl(locale) {
  if (locale === supportedLocales.de) {
    return `${urls.marketingUrl}/produkt/app-download`;
  }
  return `${urls.marketingUrl}/en/product/app-download`;
}

export const PASSWORD_SECURITY = {
  minLength: 8,
};

export function getMeetingPopupUrl({
  roomId,
  meetingServerUrl,
  remoteDesktopEnabled,
  inviteEnabled,
  callerName,
  channelName,
  lobbyEnabled,
  password,
}) {
  const params = new URLSearchParams();
  params.append('meetingServerUrl', meetingServerUrl);
  if (callerName) {
    params.append('callerName', callerName);
  }
  if (channelName) {
    params.append('channelName', channelName);
  }
  if (inviteEnabled) {
    params.append('inviteEnabled', inviteEnabled);
  }
  if (lobbyEnabled) {
    params.append('lobbyEnabled', lobbyEnabled);
  }
  if (password) {
    params.append('password', password);
  }
  if (isDesktopApp()) {
    if (remoteDesktopEnabled) {
      params.append('remoteDesktopEnabled', remoteDesktopEnabled);
    }
    return `meeting/${roomId}?${params.toString()}`;
  }
  return `${getFrontendApplicatonUrl()}/meeting/${roomId}?${params.toString()}`;
}

export function getInstantMeetingJoinUrl(roomName, locale) {
  if (locale === supportedLocales.de) {
    return `${urls.marketingUrl}/meet-free-teilnehmen?meeting-name=${roomName}`;
  }
  return `${urls.marketingUrl}/en/meet-free-join?meeting-name=${roomName}`;
}

export function getInstantMeetingThanksUrl(locale) {
  if (locale === supportedLocales.de) {
    return `${urls.marketingUrl}/call-beendet`;
  }
  return `${urls.marketingUrl}/en/call-ended`;
}

export function getInstantMeetingUrl(locale) {
  if (locale === supportedLocales.de) {
    return `${urls.marketingUrl}/meet-free`;
  }
  return `${urls.marketingUrl}/en/meet-free`;
}
export function getScheduledMeetingUrl(locale) {
  if (locale === supportedLocales.de) {
    return `${urls.marketingUrl}/meet-free-scheduled`;
  }
  return `${urls.marketingUrl}/en/meet-free-scheduled`;
}

export const getGoogleAnalyticsMeasurementId = () =>
  urls.otixoGoogleAnalyticsMeasurementId;
