import {
  getFrontendApplicatonUrl,
  isDesktopApp,
  isNodeEnvDevelopment,
} from 'config/config';

import { ALL } from './Constants/searchOptions';
import { REGISTRATION_ACTION } from 'DataLayer/Authentication/post';

export const LOAD_BACKUP = '/backup/load';

export const WORKSPACE_ONBOARDING = '/workspaces/onboarding';

export const ONBOARDING = '/onboarding';
export const REGISTER = '/register';
export const REGISTER_VERIFY_EMAIL = ':token/verify(/:invitationWorkspaceId)';
export const REGISTER_CREATE_FIRST_WORK_SPACE =
  ':token/create-first-work-space';
export const REGISTER_PURCHASE_WORKSPACE_PLAN =
  'purchase-work-space-plan/:workspaceId/:plan';
export const REGISTER_WORKSPACE_INVITATION =
  ':invitationToken/create-user/:predefinedEmail/:invitationWorkspaceId/:invitationWorkspaceName/:ownerName(/:authResult)';

export const CREATE_TEAM_SPACE = 'create-team-space';
export const DELETE_ACCOUNT_WE_TEAM_UPSELL = 'delete-account-we-team';

export const getCreateTeamspaceUrl = selectedWorkspacePlan => {
  const params = new URLSearchParams();
  if (selectedWorkspacePlan) {
    params.append(SELECTED_WORKSPACE_PLAN_QUERY_PARAM, selectedWorkspacePlan);
  }
  return Array.from(params).length > 0
    ? `${CREATE_TEAM_SPACE}?${params.toString()}`
    : CREATE_TEAM_SPACE;
};

export const getCreateFirstWorkSpaceUrl = (token, selectedWorkspacePlan) => {
  const params = new URLSearchParams();
  if (selectedWorkspacePlan) {
    params.append(SELECTED_WORKSPACE_PLAN_QUERY_PARAM, selectedWorkspacePlan);
  }
  const url = `${REGISTER}/${token}/create-first-work-space`;
  return Array.from(params).length > 0 ? `${url}?${params.toString()}` : url;
};

export const getOnboardingVerifyUrl = ({
  token,
  invitationWorkspaceId = '',
  email,
  selectedWorkspacePlan = '',
}) => {
  const params = new URLSearchParams();
  params.append('email', email);
  if (selectedWorkspacePlan) {
    params.append(SELECTED_WORKSPACE_PLAN_QUERY_PARAM, selectedWorkspacePlan);
  }
  return `${REGISTER}/${token}/verify/${invitationWorkspaceId}?${params.toString()}`;
};

export const getRegistrationUrlByAction = ({
  action,
  token,
  email,
  selectedWorkspacePlan,
}) => {
  if (action === REGISTRATION_ACTION.VERIFY_PIN) {
    return getOnboardingVerifyUrl({
      token,
      email,
      selectedWorkspacePlan,
    });
  }
  return getCreateFirstWorkSpaceUrl(token, selectedWorkspacePlan);
};

export const USER_NOT_FOUND = '/user-not-found';
export const EMAIL_CONFIRMATION = '/email-confirmation';
export const RESET_PASSWORD = '/reset-password';

export const SIGN_UP_LEGACY = '/sign-up';
export const SIGN_IN = '/sign-in';
export const LOGOUT = '/logout';
export const DRIVES = '/drives';
export const PRIVATECHAT = '/private-chat';
export const PRIVATECHAT_NOTIFICATIONS = `${PRIVATECHAT}/notification-settings`;
export const ACCOUNTS = '/accounts';
export const ADAPTERS = '/adapters';
export const WORKSPACES = '/workspaces';
export const SPACES = '/spaces';
export const LINKEDITEMS = '/linkedItems';
export const SEARCH = '/drives/search';
export const LINKS = '/links';
export const PUBLIC_SHARE = '/public';

export const SETTINGS = '/settings';
export const ACCOUNT = `${SETTINGS}/account`;
export const PROFILE = `${ACCOUNT}/profile`;
export const SECURITY = `${ACCOUNT}/security`;
export const ACCOUNT_EMAILS = `${ACCOUNT}/email`;

export const getAccountEmailsLink = returnUrl => {
  if (returnUrl) {
    return `${ACCOUNT_EMAILS}?returnUrl=${returnUrl}`;
  }
  return ACCOUNT_EMAILS;
};

export const SPACE_NOTIFICATIONS = `${ACCOUNT}/space-notifications`;
export const XXX_UPGRADE = `${ACCOUNT}/upgrade`;
export const TRANSFER_DATA_BILLING = `${ACCOUNT}/transfer-data/billing`;

export const getPublicMeetingJoinUrl = roomName =>
  `/meeting/public/join/${roomName}`;

export const getPublicMeetingStartUrl = () => `/meeting/public/start`;

export const getMeetingScheduledUrl = (id, token) =>
  `/meeting/public/scheduled/created/${id}/${token}`;

export const getFullScheduledMeetingWaitingRoomUrl = (id, locale) =>
  `${getFrontendApplicatonUrl()}${getGuestWaitingRoomUrl(id, locale)}`;

export const getGuestWaitingRoomUrl = (id, locale) =>
  `/meeting/public/scheduled/${locale}/waiting-room/${id}`;

export const getHostStartUrl = (id, locale, token) =>
  `/meeting/public/scheduled/${locale}/host-start/${id}?token=${token}`;

export const getViewScheduledMeetingUrl = (id, locale, token) => {
  const url = `/meeting/public/scheduled/${locale}/view/${id}`;
  if (token) {
    return `${url}/${token}`;
  }
  return url;
};

export const getPublicMeetingViewUrl = (roomName, displayName) =>
  `/meeting/public/${roomName}/${displayName}`;

export const getPublicMeetingInitUrl = (roomName, displayName) =>
  `${getPublicMeetingViewUrl(roomName, displayName)}/init`;

export function isInDrives(url) {
  return url.includes(DRIVES.substring(1));
}

export function isInPrivateChat(url) {
  return url.includes(PRIVATECHAT.substring(1));
}

export function getProfileLink() {
  return PROFILE;
}

export function getAccountSecurityLink() {
  return SECURITY;
}

export function getAccountDataBillingLink() {
  return TRANSFER_DATA_BILLING;
}

export const getIntegrationsBrowseUrl = workspaceId =>
  `${WORKSPACES}/${workspaceId}/integrations/browse`;

export const getIntegrationsManageUrl = workspaceId =>
  `${WORKSPACES}/${workspaceId}/integrations/manage`;

export const CREARTE_WORKSPACE = '/workspaces/create';
export const getCreateWorkspaceUrl = returnUrl =>
  `${CREARTE_WORKSPACE}?returnUrl=${returnUrl || WORKSPACES}`;

export const WORKSPACE_ADMIN = '/workspaces';
export const TRANSFER_DATA_OVERVIEW = '/transfer-data/overview';
export const TRANSFER_DATA_PURCHASE = '/transfer-data/buy';
export const TRANSFER_DATA_PURCHASE_COMPLETE = '/transfer-data/buy/complete';

export function getAdapterLink(id) {
  return `${ADAPTERS}/${id}`;
}

export function getSearchLink(query, type, date, size, resourceId) {
  const qs = [];
  let q = '';

  if (query) {
    q = query;
  }

  if (type && type !== ALL) {
    qs.push(`type=${type}`);
  }
  if (size && size !== ALL) {
    qs.push(`size=${size}`);
  }
  if (date && date !== ALL) {
    qs.push(`date=${date}`);
  }
  if (resourceId) {
    qs.push(`resourceId=${resourceId}`);
  }

  return `${SEARCH}/${q}?${qs.join('&')}`;
}

export function getLinkedItemsLink(spaceId, type, parentId, resourceId) {
  if (resourceId) {
    return `${SPACES}/${spaceId}/items/${parentId}/${resourceId}`;
  }
  return `${SPACES}/${spaceId}/items/${parentId}`;
}

export function getResourceLink(id, spaceId) {
  if (spaceId) {
    return getLinkedItemsLink(spaceId, '', id);
  }
  return `${DRIVES}/${id}`;
}

export function getPublicResourceBrowseLink(publicLinkId, resourceId) {
  return `${PUBLIC_SHARE}/browse/${publicLinkId}/${resourceId}`;
}

export function getPublicViewLink(publicLinkId, resourceId) {
  return `${PUBLIC_SHARE}/view/${publicLinkId}/${resourceId}`;
}

export function getPublicResourcePreviewLink(
  publicLinkId,
  parentResourceId,
  resourceId
) {
  return `${PUBLIC_SHARE}/preview/${publicLinkId}/${parentResourceId}/${resourceId}`;
}

export function getPublicPasswordLink(publicLinkId) {
  return `${PUBLIC_SHARE}/login/${publicLinkId}`;
}

export function getPublicLinkExpiredLink(publicLinkId) {
  return `${PUBLIC_SHARE}/expired/${publicLinkId}`;
}

export function getSpaceLink(id, query) {
  if (query) {
    const finalQuery = new URLSearchParams(query).toString();
    return `${SPACES}/${id}?${finalQuery}`;
  }
  return `${SPACES}/${id}`;
}

export function getNoteLink(channelId, noteId) {
  return `${SPACES}/${channelId}/notes/${noteId}`;
}

export const WORKSPACE_OWNER_ONBOARDING_STEPS = {
  SELECT_PLAN: 'select-plan',
  PURCHASE_COMPLETE: 'purchase-complete',
  ADD_MEMBERS: 'add-members',
  ADD_CHANNEL: 'add-channel',
  TOUR: 'tour',
};

export function getOnboardingPurchaseCompleteLink(
  channelId,
  selectedWorkspacePlan
) {
  const params = new URLSearchParams();
  params.append(WORKSPACE_OWNER_ONBOARDING_STEPS.PURCHASE_COMPLETE, true);
  params.append(SELECTED_WORKSPACE_PLAN_QUERY_PARAM, selectedWorkspacePlan);
  return `${SPACES}/${channelId}?${params.toString()}`;
}
export function getOnboardingPurchaseWorkspacePlanLink(
  channelId,
  selectedWorkspacePlan
) {
  const params = new URLSearchParams();
  params.append(WORKSPACE_OWNER_ONBOARDING_STEPS.SELECT_PLAN, true);
  if (selectedWorkspacePlan) {
    params.append(SELECTED_WORKSPACE_PLAN_QUERY_PARAM, selectedWorkspacePlan);
  }
  return `${SPACES}/${channelId}?${params.toString()}`;
}

export function getOnboardingAddMembersLink(channelId, selectedWorkspacePlan) {
  const params = new URLSearchParams();
  params.append(WORKSPACE_OWNER_ONBOARDING_STEPS.ADD_MEMBERS, true);
  if (selectedWorkspacePlan) {
    params.append(SELECTED_WORKSPACE_PLAN_QUERY_PARAM, selectedWorkspacePlan);
  }
  return `${SPACES}/${channelId}?${params.toString()}`;
}

export function getOnboardingAddChannelLink(channelId, selectedWorkspacePlan) {
  const params = new URLSearchParams();
  params.append(WORKSPACE_OWNER_ONBOARDING_STEPS.ADD_CHANNEL, true);
  if (selectedWorkspacePlan) {
    params.append(SELECTED_WORKSPACE_PLAN_QUERY_PARAM, selectedWorkspacePlan);
  }
  return `${SPACES}/${channelId}?${params.toString()}`;
}
export function getOnboardingCompleteLink(channelId) {
  const params = new URLSearchParams();
  //params.append(WORKSPACE_OWNER_ONBOARDING_STEPS.TOUR, true);
  return `${SPACES}/${channelId}?${params.toString()}`;
}

export function getLinkedItemLink(spaceId, linkedItemId) {
  return `${SPACES}/${spaceId}/file/${linkedItemId}`;
}

export function getNotificationLink(spaceId, notificationId) {
  return `${SPACES}/${spaceId}/${notificationId}`;
}

export function getSpaceMigrateLink(id) {
  return `${SPACES}/${id}/migration`;
}

export function getWorkspaceLink(id, query) {
  if (query) {
    const finalQuery = new URLSearchParams(query).toString();
    return `${WORKSPACES}/${id}?${finalQuery}`;
  }
  return `${WORKSPACES}/${id}`;
}

export function getWorkspaceGeneralLink(id) {
  return `${WORKSPACE_ADMIN}/${id}/settings/general`;
}

export function getWorkspaceIdLink(id) {
  return `${WORKSPACE_ADMIN}/${id}/settings/id`;
}

export function getWorkspaceMembersLink(id) {
  return `${WORKSPACE_ADMIN}/${id}/settings/members`;
}

export function getWorkspaceAdminLink(workspace) {
  if (workspace.IsOwner) {
    return `${WORKSPACE_ADMIN}/${workspace.Id}`;
  }
  return getWorkspaceMembersLink(workspace.Id);
}

export function getWorkspacePlanLink(id) {
  return `${WORKSPACE_ADMIN}/${id}/settings/plan`;
}

export function getWorkspacePlanInvoicesLink(id) {
  return `${WORKSPACE_ADMIN}/${id}/settings/plan/invoices`;
}

export function getWorkspaceNotificationSettingsLink(id) {
  return `${WORKSPACE_ADMIN}/${id}/settings/notifications`;
}

export function getWorkspaceUpgradeLink(id) {
  return `${WORKSPACE_ADMIN}/${id}/settings/upgrade`;
}

export function getWorkspaceExportLink(id) {
  return `${WORKSPACE_ADMIN}/${id}/settings/export`;
}

export function getWorkspaceBackupLink(id) {
  return `${WORKSPACE_ADMIN}/${id}/settings/backup`;
}

export function getWorkspacePaymentLink(id, plan) {
  return `${WORKSPACE_ADMIN}/${id}/settings/upgrade/payment/${plan}`;
}

export function getWorkspacePaymentCompleteLink(id) {
  return `${WORKSPACE_ADMIN}/${id}/settings/upgrade/payment/complete`;
}

export function getTransferDataPaymentLink(amount) {
  return `${TRANSFER_DATA_PURCHASE}/${amount}`;
}

export function getAccountLink(id) {
  return `${ACCOUNTS}/${id}`;
}

export function getLoginLink(email, continueUrl) {
  const query = [];
  if (email) {
    query.push(`email=${encodeURIComponent(email)}`);
  }
  if (continueUrl && !continueUrl.includes(SIGN_IN)) {
    // ensure the user is sent to the secure site
    const finalContinueUrl = isNodeEnvDevelopment()
      ? continueUrl
      : continueUrl.replace('http://', 'https://');
    query.push(`continue=${encodeURIComponent(finalContinueUrl)}`);
  }
  return `${SIGN_IN}?${query.join('&')}`;
}

export function getForgotPasswordLink(email) {
  const query = [];
  if (email) {
    query.push(`email=${encodeURIComponent(email)}`);
  }
  return `${RESET_PASSWORD}?${query.join('&')}`;
}

export function getSignUpLink(continueUrl) {
  const query = [];
  if (continueUrl) {
    query.push(`continue=${encodeURIComponent(continueUrl)}`);
  }
  return `${REGISTER}?${query.join('&')}`;
}

export const SELECTED_WORKSPACE_PLAN_QUERY_PARAM = 'selected-workspace-plan';
export const getSelectedWorkspacePlanQueryParam = query =>
  query[SELECTED_WORKSPACE_PLAN_QUERY_PARAM];

export function getDrivesWorkspaceLink(workspaceId) {
  return `${DRIVES}/my-work-space-files/${workspaceId}`;
}

export function getDrivesWorkspaceChannelLink(workspaceId, channelId) {
  return `${DRIVES}/my-work-space-files/${workspaceId}/${channelId}`;
}

export function getDrivesWorkspaceResourceLink(
  workspaceId,
  channelId,
  resourceId
) {
  return `${DRIVES}/my-work-space-files/${workspaceId}/${channelId}/${resourceId}`;
}

export const SHARE_FROM_DRIVES = 'share-from-drives';

export function getShareWithChannelFromDriveUrl(channelId) {
  const params = new URLSearchParams();
  params.append(SHARE_FROM_DRIVES, true);
  return `${SPACES}/${channelId}?${params.toString()}`;
}

export const MEETING_RINGBACK_AUDIO = '/meeting-ringback-audio';

export const getAbsoluteMeetingRingbackAudioUrl = () =>
  isDesktopApp()
    ? `#${MEETING_RINGBACK_AUDIO}`
    : `${getFrontendApplicatonUrl()}${MEETING_RINGBACK_AUDIO}`;
