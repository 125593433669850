import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

import COLLABORATOR_TYPE from 'Constants/collaboratorType';
import WORKSPACE_MEMBER_STATUS from 'DataLayer/Constants/workspaceMemberStatus';

import { getSpaces, getSpaceById } from '../selectors';
import { getUserId, getUser, getUserGuid } from '../../User/selectors';

import { getCollaboratorsInternal } from './collaboratorsSelectors';

export const getPresence = state => state.spaces.presence;

export const getCollaboratorsArray = createSelector(
  [getCollaboratorsInternal],
  collaborators => Object.values(collaborators)
);

export const getCollaborators = createSelector(
  [getCollaboratorsInternal],
  collaborators => collaborators
);

export const getCollaboratorsForDropdown = createSelector(
  [getCollaboratorsArray, getUserId],
  (collaborators, userId) =>
    orderBy(uniqBy(collaborators, 'Email'), 'Email').filter(
      c => c.Email !== userId
    )
);

export const getCollaboratorsBySpaceId = createSelector(
  [getSpaceById, getCollaboratorsInternal, getPresence],
  (space, collaborators, presence) => {
    //const space = spaces.find(s => s.Id === spaceId);
    if (!space) {
      return [];
    }
    if (!space.Collaborators) {
      return [];
    }

    const collaboratorsWithPresence = space.Collaborators.map(id => ({
      ...collaborators[id],
      Presence: presence[collaborators[id].Email],
    }));

    const uniqueCollaborators = uniqBy(collaboratorsWithPresence, 'Id');

    return orderBy(
      uniqueCollaborators,
      ['Status', 'FirstName'],
      ['desc', 'asc']
    );
  }
);

export const getActiveCollaboratorsBySpaceId = createSelector(
  [getCollaboratorsBySpaceId],
  collaborators =>
    collaborators.filter(
      collaborator => collaborator.Status !== WORKSPACE_MEMBER_STATUS.LEFT
    )
);

export function makeGetCollaboratorsBySpaceId() {
  return getCollaboratorsBySpaceId;
}

export const makeGetCollabratorByEmailAndSpaceId = () =>
  createSelector(
    [
      getCollaborators,
      getPresence,
      (state, props) => props.email,
      (state, props) => props.spaceId,
    ],
    (collaborators, presence, email, spaceId) => {
      const collaboratorId = `${email}:${spaceId}`;
      const collaborator = collaborators[collaboratorId];
      if (collaborator) {
        return {
          ...collaborator,
          Presence: presence[collaborator.Email],
        };
      }
      return {};
    }
  );

// is the current user a guest of a given space
export const isUserGuestBySpaceId = createSelector(
  [getCollaborators, getUserId, (_, spaceId) => spaceId],
  (collaborators, userId, spaceId) => {
    const collaboratorId = `${userId}:${spaceId}`;
    const collaborator = collaborators[collaboratorId];
    return collaborator && collaborator.Type === COLLABORATOR_TYPE.SINGLE;
  }
);

export const makeIsUserGuestBySpaceId = () =>
  createSelector([isUserGuestBySpaceId], isGuest => isGuest);

export const getOwnerBySpaceId = createSelector(
  [getSpaceById, getCollaboratorsInternal, getPresence],
  (space, collaborators, presence) => {
    //const space = spaces.find(s => s.Id === spaceId);
    if (!space) {
      return {};
    }
    const collaborator = collaborators[space.Owner];
    if (collaborator) {
      return {
        ...collaborator,
        Presence: presence[collaborator.Email],
      };
    }
    return collaborators[space.Owner] || {};
  }
);

export function makeGetOwnerBySpaceId() {
  return getOwnerBySpaceId;
}

export function getPresenceByEmail(state, email) {
  return state.spaces.presence[email];
}

export const getCollaboratorsPendingApproval = createSelector(
  [getCollaboratorsArray, getSpaces],
  (collaborators, spaces) =>
    collaborators
      .filter(collaborator => collaborator.PendingApproval)
      .filter(collaborator => {
        // Only include collaborators where I am the owner of the Space
        const space = spaces.find(s => s.Id === collaborator.SpaceId);
        return space.IsOwner;
      })
);

export const getCollaboratorsPendingApprovalCount = createSelector(
  [getCollaboratorsInternal, getSpaces],
  (collaborators, spaces) =>
    spaces.reduce((object, space) => {
      let numberOfPending = 0;
      if (space.Collaborators) {
        numberOfPending = space.Collaborators.map(
          id => collaborators[id]
        ).filter(collaborator => collaborator.PendingApproval).length;
      }
      object[space.Id] = numberOfPending;
      return object;
    }, {})
);

export const getCollaboratorByNotificationMail = createSelector(
  [getCollaborators, (_, email) => email],
  (collaborators, email) =>
    Object.values(collaborators).find(
      collaborator => collaborator.NotificationMail === email
    )
);

export const makeGetSpacesByWorkspaceIdAndNotificationEmail = () =>
  createSelector(
    [
      getSpaces,
      getCollaborators,
      (_, props) => props.workspaceId,
      (_, props) => props.email,
    ],
    (spaces, collaborators, workspaceId, email) => {
      const spacesByWorkspaceId = spaces
        .filter(space => space.WorkspaceId === workspaceId)
        .filter(space => !space.IsChat);
      const spacesByEmail = spacesByWorkspaceId.filter(space =>
        space.Collaborators.concat(space.Owner)
          .map(collaborator => collaborators[collaborator])
          .filter(
            collaborator => collaborator.Status !== WORKSPACE_MEMBER_STATUS.LEFT
          )
          .some(collaborator => collaborator.Email === email)
      );

      return orderBy(
        spacesByEmail,
        ['ProvisionalSpace', 'LastUpdateTimestamp'],
        ['desc', 'desc']
      );
    }
  );

export const makeGetChatCollaborator = () =>
  createSelector(
    [getCollaboratorsBySpaceId, getOwnerBySpaceId, getUser],
    (collaborators, owner, user) => {
      const collaborator = collaborators.find(
        c => c && user.UserID !== c.Email
      );
      if (collaborator) {
        return collaborator;
      } else {
        return owner;
      }
    }
  );

export const getMembersBySpaceId = createSelector(
  [getActiveCollaboratorsBySpaceId, getOwnerBySpaceId, (_, spaceId) => spaceId],
  (collaborators, owner, spaceId) => {
    if (!spaceId) {
      return [];
    }
    const joinedMembers = collaborators.filter(
      collaborator => collaborator.Status === WORKSPACE_MEMBER_STATUS.JOINED
    );
    return sortBy(joinedMembers.concat(owner), [
      member => member.Name?.toLowerCase(),
    ]);
  }
);

export const getMemberIdsExcludingUserBySpaceId = createSelector(
  [getMembersBySpaceId, getUserGuid],
  (members, userGuid) =>
    members
      .filter(member => member.UserId !== userGuid)
      .map(member => member.UserId)
);
